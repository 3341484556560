import {
  Box,
  Grid,
  makeStyles,
  ThemeProvider,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import React from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import { HrefButton } from '../common/HrefButton'

export enum PricingStyle {
  STARTER,
  GROWTH,
  ENTERPRISE,
}

export type FormattedString = {
  textRuns: TextRun[]
}

type TextRun = {
  text: string
  bold?: boolean
  strike?: boolean
}

type PricingItemProps = {
  style: PricingStyle
  title: string
  cost: FormattedString
  perks: FormattedString[]
  buttonText: string
  buttonLink: string
}

interface PricingTheme {
  color: string
  borderColor: string
}

const starterTheme: PricingTheme = {
  color: '#000000',
  borderColor: '#000000',
}

const growthTheme: PricingTheme = {
  color: '#7891E6',
  borderColor: '#5069BF',
}

const enterpriseTheme: PricingTheme = {
  color: '#A571CD',
  borderColor: '#73558A',
}

const FormattedStringComp = ({ formattedString }: FormattedStringProps) => {
  return (
    <React.Fragment>
      {formattedString.textRuns.map(textRun => {
        if (textRun.bold) {
          return <b>{textRun.text}&nbsp;</b>
        } else if (textRun.strike) {
          return <strike>{textRun.text}</strike>
        } else {
          return <p style={{ margin: '0' }}>{textRun.text}</p>
        }
      })}
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  pricingItem: {
    width: '413px',
    height: '522px',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderRadius: '10px',
    [theme.breakpoints.down('lg')]: {
      margin: '40px auto 0 auto',
      maxWidth: '303px',
      width: '100%',
      height: '550px',
    },
  },
}))

type FormattedStringProps = {
  formattedString: FormattedString
}

const PricingItem = ({
  style,
  title,
  cost,
  perks,
  buttonText,
  buttonLink,
}: PricingItemProps) => {
  const classes = useStyles()

  let theme: PricingTheme
  switch (style) {
    case PricingStyle.STARTER:
      theme = starterTheme
      break
    case PricingStyle.GROWTH:
      theme = growthTheme
      break
    case PricingStyle.ENTERPRISE:
      theme = enterpriseTheme
      break
  }
  const starter = style === PricingStyle.STARTER

  return (
    <Box
      className={classes.pricingItem}
      style={{
        borderColor: theme.borderColor,
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{
          borderTop: !starter ? `20px solid ${theme.color}` : '',
          borderRadius: '7px',
          padding: '20px',
          paddingTop: starter ? '40px' : '20px',
          width: '100%',
          height: '100%',
        }}
      >
        <Typography
          variant="h6"
          style={{
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '25px',
          }}
        >
          <FormattedStringComp formattedString={cost} />
        </Typography>
        <Box>
          {perks.map(perk => {
            return (
              <Typography
                variant="subtitle1"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <FormattedStringComp formattedString={perk} />
              </Typography>
            )
          })}
        </Box>
        <Grid
          container
          alignItems="flex-end"
          justify="center"
          style={{
            flexGrow: 1,
            marginBottom: '20px',
          }}
        >
          <ThemeProvider
            theme={createMuiTheme({
              palette: {
                primary: {
                  main: theme.color,
                },
              },
            })}
          >
            <HrefButton
              href={buttonLink}
              disableElevation
              color="primary"
              variant={starter ? 'outlined' : 'contained'}
              style={{
                fontFamily:
                  '"Ubuntu", "Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 500,
              }}
            >
              {buttonText}
            </HrefButton>
          </ThemeProvider>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PricingItem
