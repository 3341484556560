import React, { FunctionComponent } from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'
import { navigate } from 'gatsby'

const HrefButton: FunctionComponent<ButtonProps & { href: string }> = props => {
  const onClick = () => navigate(props.href)
  const finalProps = { ...props }
  delete finalProps.href
  return (
    <Button {...finalProps} onClick={onClick}>
      {' '}
      {props.children}{' '}
    </Button>
  )
}

export { HrefButton }
