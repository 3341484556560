import { FormattedString } from '../pricing_item/pricing_item'
import pricingPlans from '../../utils/pricingPlans'

type PricingConfig = {
  title: string
  cost: FormattedString
  perks: FormattedString[]
  buttonText: string
  buttonLink: string
}

export const starterPricing: PricingConfig = {
  title: 'Individual',
  cost: {
    textRuns: [
      {
        text: 'FREE ',
        bold: true,
      },
    ],
  },
  buttonText: 'Start For Free',
  buttonLink: pricingPlans.visibleIndividualPlan.signupUrl,
  perks: [
    {
      textRuns: [
        {
          text: '1 Annotator',
        },
      ],
    },

    {
      textRuns: [
        {
          text: 'Unlimited Annotations',
        },
      ],
    },

    {
      textRuns: [
        {
          text: 'Span Annotations Classifications & Relationships',
        },
      ],
    },
    {
      textRuns: [
        {
          text: 'API Access',
          bold: true,
        },
      ],
    },
    {
      textRuns: [
        {
          text: 'LightTag AI Suggestions',
          strike: false,
        },
      ],
    },
    {
      textRuns: [
        {
          text: 'Bring Your Own Pre-Annotations',
          strike: true,
        },
      ],
    },
  ],
}

export const growthPricing: PricingConfig = {
  title: 'Team',
  cost: {
    textRuns: [
      {
        text: 'Starting at $0',
        bold: true,
      },
      {
        text: '/mo',
      },
    ],
  },
  buttonText: 'Start For Free',
  buttonLink: pricingPlans.visibleTeamPlan.signupUrl,
  perks: [
    {
      textRuns: [
        {
          text: 'Unlimited ',
          bold: true,
        },
        {
          text: 'Annotators',
        },
      ],
    },
    {
      textRuns: [
        {
          text: 'Free up to 10K',
          bold: true,
        },
        {
          text: 'Annotations /Month',
        },
      ],
    },
    {
      textRuns: [
        {
          text: '$0.02 / Additional Annotations',
        },
      ],
    },
    {
      textRuns: [
        {
          text: 'Span Annotations Classifications & Relationships',
        },
      ],
    },
    {
      textRuns: [
        {
          text: 'API Access',
          bold: true,
        },
      ],
    },
    {
      textRuns: [
        {
          text: 'LightTag AI Suggestions',
          bold: true,
        },
      ],
    },
    {
      textRuns: [
        {
          text: 'Bring Your Own Pre-Annotations',
          bold: true,
        },
      ],
    },
  ],
}

export const enterprisePricing: PricingConfig = {
  title: 'On Premise',
  cost: {
    textRuns: [
      {
        text: 'Deploy LightTag on Your Own Premises',
      },
    ],
  },
  buttonText: 'Contact Us',
  buttonLink: '/contact',
  perks: [
    {
      textRuns: [
        {
          text: 'Everything in Teams +',
          bold: true,
        },
      ],
    },
    {
      textRuns: [
        {
          text: 'LDAP/SSO',
          bold: true,
        },
      ],
    },
    {
      textRuns: [
        {
          text: 'Role Based Access Controls',
          bold: true,
        },
      ],
    },
    {
      textRuns: [
        {
          text: 'Audit Logs',
          bold: true,
        },
      ],
    },
    {
      textRuns: [
        {
          text: 'Customization and Training',
          bold: true,
        },
      ],
    },
  ],
}
