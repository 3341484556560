import React from 'react'
import Layout from '../../components/layout'
import { fbViewedPricing } from '../../utils/analytics'
import PricingSection from '../../components/pricing_section/pricing_section'

const Pricing = props => {
  React.useEffect(fbViewedPricing, [1])
  return (
    <Layout>
      <PricingSection />
    </Layout>
  )
}
export default Pricing
