import { Grid, makeStyles, Typography } from '@material-ui/core'
import PricingItem, { PricingStyle } from '../pricing_item/pricing_item'
import Button from '@material-ui/core/Button'
import {
  enterprisePricing,
  growthPricing,
  starterPricing,
} from './pricing_confgs'

const featureBreakdown = 'FULL FEATURE BREAKDOWN'

const useStyles = makeStyles(theme => ({
  pricingGrid: {
    padding: '70px 0',
    [theme.breakpoints.down('md')]: {
      padding: '0',
      flexDirection: 'column',
    },
  },

  pricingItems: {
    maxWidth: '1359px',
    marginBottom: '60px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}))

const PricingSection = () => {
  const classes = useStyles()
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.pricingGrid}
      style={{paddingBottom:0}}
    >
      <Grid
        container
        item
        direction="row"
        justify="space-between"
        className={classes.pricingItems}
      >
        <PricingItem
          style={PricingStyle.STARTER}
          title={starterPricing.title}
          cost={starterPricing.cost}
          perks={starterPricing.perks}
          buttonText={starterPricing.buttonText}
          buttonLink={starterPricing.buttonLink}
        />
        <PricingItem
          style={PricingStyle.GROWTH}
          title={growthPricing.title}
          cost={growthPricing.cost}
          perks={growthPricing.perks}
          buttonText={growthPricing.buttonText}
          buttonLink={growthPricing.buttonLink}
        />
        <PricingItem
          style={PricingStyle.ENTERPRISE}
          title={enterprisePricing.title}
          cost={enterprisePricing.cost}
          perks={enterprisePricing.perks}
          buttonText={enterprisePricing.buttonText}
          buttonLink={enterprisePricing.buttonLink}
        />
      </Grid>
      <Grid item xs={12} container style={{ backgroundColor: '#F8F9FC',padding:70 }} className={classes.pricingGrid}>
        <Grid item xs={12}  container
              justify={"space-around"}
              alignItems="center"
              >
          <Grid item xs={2}>
            <Typography variant={'h3'}>Free For Education</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant={'body1'}>
              LightTag is free to use for those who attend and teach at
              qualified educational institutions to use for non-commercial
              research.
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button href={"/signup/academic"} fullWidth variant={"contained"} color={"primary"}>Apply Now</Button>
          </Grid>
        </Grid>
      </Grid>
      <Button
        href="/features"
        disableElevation
        size="large"
        style={{
          marginBottom: '140px',
        }}
      >
        <Typography variant="h5">{featureBreakdown}</Typography>
      </Button>
    </Grid>
  )
}

export default PricingSection
